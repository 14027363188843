<template>
    <div>
        <van-nav-bar title="审核日报" left-text="返回" @click-left="back" fixed left-arrow/>
        
        <div class="login_form">
            <van-sticky offset-top="1.22667rem" style="margin-bottom:10px">
                <van-cell title="选择日期" :value="selectDateValue" @click="selectShow = true" class="dateSelectCell">
                    <template #extra>
                        <van-icon v-if="selectDateValue" name="close" style="line-height:0.64rem;position:relative;left:5px;" @click.stop="selectDateClear"/>
                    </template>
                </van-cell>
                <van-calendar v-model="selectShow" :min-date="minDate" type="range" @confirm="selectConfirm" />
                <van-cell title="选择人员" :value="userNameValue" @click="selectUserClick" value-class="userNameValue">
                    <template #extra>
                        <van-icon v-if="userNameValue" name="close" style="line-height:0.64rem;position:relative;left:5px;" @click.stop="selectUserClear"/>
                    </template>
                    <template #default>
                        <span v-if="user.userNameNeedTranslate == '1'">
                            <span v-for="item,index in userNameValue.split(',')" :key="item">
                                <TranslationOpenDataText type='userName' :openid='item'></TranslationOpenDataText>
                                <span v-if="index != (userNameValue.split(',').length - 1)">,</span>
                            </span>
                        </span>
                        <span v-else>{{userNameValue}}</span>
                    </template>
                </van-cell>
                <van-popup v-model="selectUserShow" position="bottom" style="height: 90%">
                    <!-- <van-search v-model="searchInputValue" placeholder="输入员工姓名搜索" @search="userListSearch" v-if="user.userNameNeedTranslate != '1'"></van-search>
                    <div style="minHeight:300px;">
                        <template v-if="user.userNameNeedTranslate == '1'">
                            <van-checkbox class="userCheckbox" v-for="(item) in showUserList" :key="item.id" v-model="item.isChecked" >
                                <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText>
                            </van-checkbox>
                        </template>
                        <template v-else>
                            <van-checkbox class="userCheckbox" v-for="(item) in showUserList" :key="item.id" v-model="item.isChecked" >
                                <span>{{item.name}}</span>
                            </van-checkbox>
                        </template>
                        <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="selectUserConfirm()">确定</van-button>
                    </div> -->
                    <div class="popupDiv">
                        <div class="popupSearch" v-if="user.userNameNeedTranslate != '1'">
                            <van-search v-model="searchInputValue" placeholder="输入员工姓名搜索" @search="userListSearch" shape="round" background="#F4F4F4"></van-search>
                        </div>

                        <div class="popupCon conBorder">
                            <div class="popupConBox" v-if="user.userNameNeedTranslate == '1'">
                                <van-checkbox class="popupItem marginNone borderNone" v-for="(item) in showUserList" :key="item.id" v-model="item.isChecked" >
                                    <span class="userNameClass_left">
                                        <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText>
                                    </span>
                                    <span class="userNameClass_right">
                                        {{ item.jobNumber }}
                                    </span>
                                </van-checkbox>
                            </div>
                            <div class="popupConBox" v-else>
                                <van-checkbox class="popupItem marginNone borderNone" v-for="(item) in showUserList" :key="item.id" v-model="item.isChecked" >
                                    <span class="userNameClass_left">{{item.name}}</span>
                                    <span class="userNameClass_right">{{ item.jobNumber }}</span>
                                </van-checkbox>
                            </div>
                        </div>

                        <div class="popupBtn">
                            <van-button style="width:100%;background: #1989fa;color: #ffffff;" round @click="selectUserConfirm()">确定</van-button>
                        </div>
                    </div>
                </van-popup>
            </van-sticky>

            <div class="formBatch">
                <van-checkbox v-model="isAllChecked" :disabled="report.length == 0" @click="allChecked" shape="square" style="padding-left:3vw"></van-checkbox>
                <div style="padding:1vh 2vw">
                <van-button @click="batchAgree(true)" :disabled="!isCanAgree || report.length == 0" type="info" size="small" style="margin-right:6vw">批量通过</van-button>
                <van-button @click="batchAgree(false)" :disabled="!isCanAgree || report.length == 0" type="danger" size="small" style="margin-left:2vw">批量驳回</van-button>
                </div>
            </div>
            <van-skeleton  v-for="(item,index) in report" :key="index" title avatar :row="3" :loading="false">
                <van-panel class="one_report">
                    <template #header>
                        <div class="van-cell van-panel__header">
                            <div class="van-cell__title" style="display:flex;algin-items:center">
                                <van-checkbox v-model="item.checked" @click="itemChecked" shape="square" style="margin-right:2vw"></van-checkbox>
                                <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                                <span v-else>{{item.name}}</span>
                            </div>
                            <div class="van-cell__value van-panel__header-value">{{item.state==0?(user.company.packageEngineering == 1 ? (item.data[0].departmentAuditState==-1?'待专业审核': (item.data[0].departmentAuditState==0?'待部门审核': '待项目经理审核')) : '待审核'):item.state==1?'已通过':'已驳回'}}</div>
                        </div>
                    </template>
                    <div class="form_text">
                        <span style="margin-left:5px;">工作日期: {{item.dateStr}}</span>
                        <span style="float:right;">
                            <i v-if="parseFloat(item.reportTime)>parseFloat(item.calculateTime)+0.5" style="color:red;margin-right:8px;" class="fa fa-exclamation-triangle"></i>
                            总填报：
                            <span :style="parseFloat(item.reportTime)>parseFloat(item.calculateTime)+0.5?'color:red':''">{{item.reportTime}}h</span>
                        </span>
                        <!-- <span>系统智能统计：{{item.calculateTime}}h</span> -->
                    </div>
                    <div v-for="(item1,index1) in item.data" :key="index1" class="one_report_data">
                        <div class="project_title">项目：{{item1.project}}
                            [
                            <span v-if="user.timeType.reportAuditType == 7" >
                                审核人：<span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                                    :openid='item1.projectAuditorName'></TranslationOpenDataText><span v-if="item1.projectAuditState==1">√</span><span v-if="item1.deptAuditorName">、</span>
                                                <TranslationOpenDataText type='userName'
                                                    :openid='item1.deptAuditorName'></TranslationOpenDataText><span v-if="item1.departmentAuditState==1">√</span>
                                        </span>
                                        <span v-else>{{ item1.projectAuditorName }}<span v-if="item1.projectAuditState==1">√</span><span v-if="item1.deptAuditorName">、</span>{{ item1.deptAuditorName }}<span v-if="item1.departmentAuditState==1">√</span></span>
                            </span>
                            <span v-else>
                                <span v-if="item1.isDeptAudit==0">
                                    <span v-if="item1.projectAuditState==0">
                                        待项目审核人<span v-if="item1.projectAuditorName != null">(
                                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item1.projectAuditorName'></TranslationOpenDataText></span>
                                            <span v-else>{{item1.projectAuditorName}}</span>
                                        )</span>审核
                                    </span>
                                    <span style="color:#32CD32;" v-else-if="item1.projectAuditState==1">
                                        项目审核人<span v-if="item1.projectAuditorName != null">(
                                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item1.projectAuditorName'></TranslationOpenDataText></span>
                                            <span v-else>{{item1.projectAuditorName}}</span>
                                        )</span>审核通过
                                    </span>
                                </span>
                                <span v-else-if="item1.isDeptAudit==1">
                                    待
                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='departmentName' :openid='item1.auditDeptName'></TranslationOpenDataText></span>
                                    <span v-else>{{item1.auditDeptName}}</span>
                                    审核
                                </span>
                            </span>
                            ]

                        </div>
                        <div class="project_title" v-if="item1.projectCode != null" >{{ user.companyId == '7030' ? '项目令号' : '项目编号' }}：{{item1.projectCode}}</div>
                        <div class="project_title" v-if="item1.subProjectName != null && user.companyId != yuzhongCompId" >子项目：{{item1.subProjectName}}</div>
                        <div class="project_title" v-if="user.companyId == yuzhongCompId" >角色：{{item1.extraField1?roleList.find(r=>r.value==item1.extraField1).label:''}}</div>
                        <div class="project_title" v-if="user.company.packageProject==1&&item1.groupName != null" >任务分组：{{item1.groupName}}</div>
                        <div class="project_title" v-if="user.companyId == yuzhongCompId" >工作职责：{{item1.extraField2?item1.extraField2Name:''}}</div>
                        <div class="project_title" v-if="user.companyId == yuzhongCompId" >工作内容：{{item1.extraField3?item1.extraField3Name:''}}</div>
                        <div class="project_title" v-if="user.company.packageProject==1&&item1.stage != '-' && user.companyId != yuzhongCompId" >投入阶段：{{item1.stage}}</div>
                        <!--自定义维度 -->
                        <div class="project_title" v-if="user.timeType.customDegreeActive == 1" >{{user.timeType.customDegreeName}}：{{item1.degreeName}}</div>
                        <div class="project_title" v-if="user.timeType.customDataActive == 1" >{{user.timeType.customDataName}}：{{item1.customData}}</div>
                        <div class="project_title" v-if="user.timeType.customTextActive == 1" >{{user.timeType.customTextName}}：{{item1.customText}}</div>
                        <div class="project_title" v-if="user.company.packageEngineering == 1">
                            专业进度：
                            <span style="margin-right:10px;" v-for="progressItem in item1.professionProgressList" :key="progressItem.id">{{progressItem.professionName}}({{progressItem.progress}}%) 
                            </span>
                        </div>
                        
                        <div class="project_title" v-if="item1.taskId != null" >任务：{{item1.taskName}}</div>
                        <!--根据类型选择使用的模板 -->
                        <div v-if="item1.multiWorktime == 0">
                            <div class="project_time">时长：
                                <span v-if="item1.reportTimeType == 0" style="margin-right:10px;">{{fullDayTxt[item1.timeType]}}</span>
                                <span v-if="item1.reportTimeType == 2" style="margin-right:10px;">{{item1.startTime+'-'+item1.endTime}}</span>{{item1.time.toFixed(1)}}h
                                <div class="button" v-if="item1.isOvertime == 1">加班<span v-if="item1.overtimeHours">{{item1.overtimeHours.toFixed(1)}}h</span></div>
                            </div>
                            <div class="project_content">事项：<span v-html="item1.content"></span></div>
                        </div>
                        <div v-if="item1.multiWorktime == 1">
                            <div>
                                项目时长：<span style="margin-right:10px;">{{item1.time.toFixed(1)}}h</span>
                            <div class="button" v-if="item1.isOvertime == 1" style="width: 2.3rem">加班<span v-if="item1.overtimeHours">{{item1.overtimeHours.toFixed(1)}}h</span></div>
                            </div>
                            <div style="position:relative;border:#ccc 0.5px solid;padding:3px;margin:5px 0px;" v-for="(timeItem, index) in item1.worktimeList" :key="index" >
                                <div class="project_time">时长：
                                    <!-- <span v-if="timeItem.reportTimeType == 0" style="margin-right:10px;">{{fullDayTxt[item1.timeType]}}</span> -->
                                    <span style="margin-right:10px;">{{timeItem.startTime+'-'+timeItem.endTime}}</span>{{timeItem.time.toFixed(1)}}h
                                </div>
                                <div class="project_content">事项：<span v-html="timeItem.content"></span></div>
                            </div>
                        </div>
                        <div style="padding:5px;text-align:center;" v-if="item1.pics != null && item1.pics.length > 0">
                            <span v-for="(p, index) in item1.pics"  :key="p" style="margin-right:10px;">
                            <img  :src="p" style="width:80px; height:80px;" @click="showLargeImg(item1.pics, index)"/>
                            </span>
                        </div>
                    </div>
                    <div class="form_btn" slot="footer">
                        <van-button size="small" type="info" @click="approve(item.id, item)" style="margin-right: 4vw;">通过</van-button>
                        <van-button size="small" type="danger" @click="showDenyDialog(item.id,0,item.dateStr, item)">驳回</van-button>
                    </div>
                    <van-popup v-model="imgShow" position="bottom" closeable >
                        <van-swipe class="my-swipe"  indicator-color="white">
                        <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                            <img :src="picItem" style="width:100%;" />
                        </van-swipe-item>
                        </van-swipe>
                    </van-popup>
                </van-panel>
            </van-skeleton>
            <van-popup v-model="denyReasonDialog" position="bottom" closeable >
                <van-cell>请输入原因</van-cell>
                <van-field class="form_input"
                    v-model="denyForm.reason" name="reason" type="textarea" :placeholder="'请输入您决定'+(denyForm.i==0?'驳回':'撤销')+'的原因'"
                    rows="3" autosize  />
                <van-button style="width:100%;" type="info" @click="deny()">提交</van-button>
            </van-popup>
            <van-popup v-model="approveinDialog" v-if="approveinDialog" position="bottom" closeable >
                <van-cell>请输入通过评价</van-cell>
                <van-field class="form_input"
                    v-model="approveinData.evaluate" name="reason" type="textarea" placeholder="请输入您决定通过的评价"
                    rows="3" autosize  />
                <van-button style="width:100%;" type="info" @click="batchApproveinfun()" v-if="isbatch">提交</van-button>
                <van-button style="width:100%;" type="info" @click="approveinfun()" v-else>提交</van-button>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                yuzhongCompId: 3385,
                roleList:[{value: 1,label: 'CRC&LM'},{value: 2,label: 'PM'}],
                isAllChecked: false,
                fullDayTxt:['全天','上午','下午'],
                denyForm:{reason:null},
                denyReasonDialog:false,
                tmpPics:[],
                imgShow: false,
                user: JSON.parse(localStorage.userInfo),
                minDate: new Date(2010, 0, 1),
                maxDate: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
                currentDate: new Date(),
                nowTime: this.format(new Date(new Date()),"yyyy-MM-dd"),
                showPicker: false,
                report: [],
                flg: false,
                isCanAgree: false,
                approveinData: null,
                approveinDialog: false,
                isbatch: false,

                selectDate: [],
                selectDateValue: '',
                selectShow: false,
                userList: [],
                showUserList: [],
                userIdList: [],
                userNameValue: '',
                searchInputValue: '',
                selectUserShow: false
            };
        },
        created() {
        },
        methods: {
            selectDateClear(){
                this.selectDate = []
                this.selectDateValue = ''
                this.getReport()
            },
            selectUserClear(){
                this.userNameValue = ''
                this.userIdList = []
                this.userList.forEach(item => {delete item.isChecked})
                this.showUserList = this.userList
                this.getReport()
            },
            selectUserClick(){
                this.selectUserShow = true
                this.searchInputValue = ''
                this.showUserList = this.userList
            },
            selectConfirm(date){
                const [start, end] = date;
                this.selectShow = false;
                this.selectDate = [this.getSelectDateStr(start),this.getSelectDateStr(end)]
                this.selectDateValue = `${this.selectDate[0]} 至 ${this.selectDate[1]}`;
                this.getReport()
            },
            getSelectDateStr(date){
                return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
            },
            userListSearch(val) {
                this.showUserList = [];
                this.userList.forEach(u=>{if (u.name.startsWith(val)) {
                    this.showUserList.push(u);
                }})
            },
            selectUserConfirm(){
                this.userNameValue = '';
                var that = this;
                that.userIdList = '';
                this.userList.filter(u=>u.isChecked).forEach(u=>{
                    that.userNameValue += (u.name+',');
                    that.userIdList += u.id + ','
                });
                if (this.userNameValue.length > 0) {
                    this.userNameValue = this.userNameValue.substring(0, this.userNameValue.length-1);
                    this.userIdList = this.userIdList.substring(0, this.userIdList.length-1);
                }
                this.$forceUpdate()
                this.selectUserShow = false
                this.getReport()
                
            },

            getUsers() {
                this.$axios.post("/user/getEmployeeList", {
                    departmentId: -1,
                    pageIndex: 1,
                    // pageSize: 99999
                    pageSize: -1
                })
                .then(res => {
                    if(res.code == "ok") {
                        this.userList = res.data.records;
                        this.showUserList = res.data.records
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },


            // 批量操作
            allChecked(){
                if(this.isAllChecked){
                    for(let i in this.report){
                        this.report[i].checked = true
                    }
                    this.isCanAgree = true
                }else{
                    for(let i in this.report){
                        this.report[i].checked = false
                    }
                    this.isCanAgree = false
                }
            },
            itemChecked(){
                let isall = true
                let iscan = false
                for(let i in this.report){
                    if(!this.report[i].checked){
                        isall = false
                    }else {
                        iscan = true
                    }
                }
                this.isAllChecked = isall
                this.isCanAgree = iscan
            },
            batchAgree(bol){
                
                let ids = ''
                for(let i in this.report){
                    if(this.report[i].checked){
                        for(let m in this.report[i].data){
                            ids += this.report[i].data[m].id + ','
                        }
                    }
                }
                if(ids.length > 0){
                    ids = ids.substring(0, ids.length-1);
                }
                if(bol){
                    Dialog.confirm({
                        message: `确认批量通过这些的日报吗？`
                    })
                    .then(() => {
                        this.isbatch = true
                        this.approveinData = {
                            ids: ids
                        }
                        if(this.user.timeType.needEvaluate == 1){
                            this.$set(this.approveinData,'evaluate','')
                            this.approveinDialog = true
                        }else{
                            const toast = this.$toast.loading({
                                forbidClick: true,
                                duration: 0
                            });
                            this.batchApproveinfun()
                        }
                    })
                    .catch(() => {});
                }else{
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    let that = this
                    this.$axios.post('/report/batchDenyReport', {
                        ids: ids
                    }).then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            setTimeout(function() {
                                that.$toast.success('审核成功');
                            }, 300);
                            this.getReport();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail('批量操作失败:'+res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                }
                
            },
            batchApproveinfun(){
                let that = this
                this.$axios.post('/report/batchApproveReport', this.approveinData)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        setTimeout(function() {
                            that.$toast.success('审核成功');
                        }, 300);
                        this.approveinDialog = false
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('批量操作失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            showLargeImg(item, index) {
                this.imgShow = true;
                this.tmpPics = item;
            },
            // 返回
            back() {
                history.back();
            },

             // 时间转换
            format(date, pattern) {
                pattern = pattern || "yyyy-MM-dd";
                var _this = this;
                return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                    switch ($0.charAt(0)) {
                        case 'y': return _this.padding(date.getFullYear(), $0.length);
                        case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                        case 'd': return _this.padding(date.getDate(), $0.length);
                        case 'w': return date.getDay() + 1;
                        case 'h': return _this.padding(date.getHours(), $0.length);
                        case 'm': return _this.padding(date.getMinutes(), $0.length);
                        case 's': return _this.padding(date.getSeconds(), $0.length);
                    }
                });
            },

            padding(s, len) {
                var len = len - (s + '').length;
                for (var i = 0; i < len; i++) { s = '0' + s; }
                return s;
            },

            // 改变时间
            changeTime(time) {
                this.nowTime = this.format(new Date(time),"yyyy-MM-dd");
                this.currentDate = time;
                this.showPicker = false;
                this.getReport();
            },

            // 获取日报
            getReport() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                let parameter = {
                    state: 0
                }
                if(this.selectDate.length != 0){
                    parameter.startDate = this.selectDate[0]
                    parameter.endDate = this.selectDate[1]
                }
                if(this.userIdList){
                    parameter.userId = this.userIdList
                }
                this.$axios.post("/report/listByState", parameter)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                        // this.isAllChecked = this.report.length == 0 ? false : true
                        if(this.report.length == 0){
                            this.isAllChecked = false
                        }
                        for(let i in this.report){
                            this.$set(this.report[i],'checked',false)
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            approve(id, item) {
                console.log(item, '<==== 点击')
                Dialog.confirm({
                    message: `确认通过${item.dateStr}的日报吗？`
                })
                .then(() => {
                    var ids = '';
                    var data = item.data;
                    data.forEach(element => {
                        if (element.id != null && element.id != '') {
                            ids +=(element.id+',');
                        }
                    });
                    this.isbatch = false
                    this.approveinData = {
                        id: id,
                        date: this.nowTime,
                        reportIds: ids
                    }
                    if(this.user.timeType.needEvaluate == 1){
                        this.$set(this.approveinData,'evaluate','')
                        this.approveinDialog = true
                    }else{
                        const toast = this.$toast.loading({
                            forbidClick: true,
                            duration: 0
                        });
                        this.approveinfun()
                    }
                })
                .catch(() => {});
            },
            approveinfun(){
                let that = this;
                this.$axios.post("/report/approve", this.approveinData)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        setTimeout(function() {
                            that.$toast.success('审核成功');
                        }, 300);
                        this.approveinDialog = false
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        setTimeout(function() {
                            that.$toast.fail('审核失败');
                        }, 300);
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            showDenyDialog(id,i,date, item) {
                console.log(id, i, date, item)
                this.denyReasonDialog = true;
                var ids = '';
                var data = item.data;
                data.forEach(element => {
                    if (element.id != null && element.id != '') {
                        ids +=(element.id+',');
                    }
                });
                this.denyForm = {id: id ,i:i, date: date, reportIds: ids, reason:null};
                console.log(this.denyForm)
            },

            deny() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                let that = this;
                this.$axios.post("/report/deny", this.denyForm)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        setTimeout(function() {
                            that.$toast.success(that.denyForm.i==0?'驳回成功':'撤销成功');
                        }, 300);
                        
                        this.getReport();
                        this.denyReasonDialog = false;
                    } else {
                        this.$toast.clear();
                        setTimeout(function() {
                            that.$toast.fail(that.denyForm.i==0?'驳回失败':'撤销失败');
                        }, 300);
                        
                    }
                }).catch(err=> {this.$toast.clear();});
            }
        },

        mounted() {
            this.getReport();
            this.getUsers()
            var list = this.user.functionList
            for(var i in list) {
                if(list[i].name == '审核全员日报') {
                    this.flg = true
                }
            }
        }
    };
</script>

<style lang="less" scoped>
.dateSelectCell{
    display: -webkit-box;
}
.userCheckbox {
        padding: 10px;;
    }
    .userNameValue{
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .formBatch{
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        border-top: 1px solid #ebebeb;
    }
    .login_form {
        margin-top: 46px;
        margin-bottom: 65px;
    }

    .one_report {
        margin-bottom: 15px;
        font-size:14px;
    }

    .form_text {
        margin: 10px 0 10px;
        padding: 0 12px;
    }
    
    .form_btn {
        text-align: right;
    }

    .form_btn button {
        margin-left: 10px;
    }

    .one_report_data {
        margin-bottom: 10px;
        padding: 0 22px;
        div {
            line-height: 30px;
        }
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    // 显示加班样式
    .one_span {
        width: 40px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid #fde2e2;
        background: #fef0f0;
        color: #f56c6c;
        font-size: 12px;
        border-radius: 5px;
        // margin-left: 40px;
        float: right;
    }
</style>
